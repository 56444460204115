const element = document.querySelector('.js-method');
const rulesField = document.querySelector('.js-rules');
const shippingFields = document.querySelector('.js-address-container');

export function init() {
	if (element) {
		element.addEventListener('change', event => {
			if (event.target.name !== 'shippingMethodHandle') {
				return;
			}

			// const shippingMethodHandleInputs = Array.from(element.querySelectorAll('[name="shippingMethodHandle"]'));
			// const rulesFieldInput = rulesField.querySelector('[name="shippingRule"]');
			// const checkedOption = shippingMethodHandleInputs.filter(input => input.checked === true);

			// if (rulesFieldInput.checked == false) {
			// 	rulesFieldInput.checked = true;
			// } else {
			// 	rulesFieldInput.checked = false;
			// }

			// rulesField.classList.toggle('inactive', checkedOption.value);
			shippingFields.classList.toggle('is-active');
		});
	}

	if (rulesField) {
		rulesField.addEventListener('change', event => {
			if (event.target.name !== 'shippingRule') {
				return;
			}

			const shippingMethodHandleInputs = Array.from(element.querySelectorAll('[name="shippingMethodHandle"]'));

			shippingMethodHandleInputs.forEach(input => {
				if (input.checked == false) {
					input.checked = true;
				} else {
					input.checked = false;
				}
			});

			rulesField.classList.toggle('inactive');
			shippingFields.classList.toggle('is-active');
		});
	}
}
